<template>
  <v-container>
    <v-row class="bg-ghost-white p-y-6">
      <v-col lg="3" sm="12">
        <BackButton :handler="goToEvents"/>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" lg="12" md="12">
      <div class="text-base font-semibold black-text ml-1 mt-6">Event Booking Configurations</div>
      <v-card class="mb-8 rounded-2 bordered shadow-0 mt-2">
        <v-card-text>
          <v-col cols="12" lg="3" md="4" sm="6" class="mb-4">
            <v-switch
                v-model="configuration.enable_tag_restriction"
                :false-value="0"
                :true-value="1"
                class="mx-4"
                dense
                hide-details="auto"
                label="Participant Tag Restriction"
                required
            ></v-switch>
          </v-col>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" lg="12" md="12">
        <v-form ref="cform">
          <div class="text-base font-semibold black-text ml-1 mb-2">Quick Scan Tags</div>
          <v-row>
            <v-col
                v-for="(code, cIndex) in colorCodes"
                :key="`index${cIndex}`"
                cols="12"
                lg="4"
                md="6"
                sm="12"
                style="position: relative"
            >
              <v-card class="shadow-2">
                <v-container>
                  <v-row>
                    <v-col class="pb-0" cols="12" md="12" sm="12">
                      <label for="">
                        Tag name
                      </label>
                      <v-text-field
                          v-model="code.name"
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          light
                          outlined
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <label for="">
                        Color Code
                      </label>
                      <v-text-field
                          v-model="code.color_code"
                          :rules="[(v) => !!v || 'Color code is required']"
                          background-color="#fff"
                          class="q-text-field shadow-0 color-picker"
                          dense
                          hide-details="auto"
                          light
                          outlined
                          required
                      >
                        <template v-slot:append>
                          <v-menu
                              :close-on-content-click="false"
                              nudge-bottom="105"
                              nudge-left="16"
                              top
                          >
                            <template v-slot:activator="{ on }">
                              <div :style="swatchStyle(cIndex)" v-on="on"/>
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker
                                    v-model="code.color_code"
                                    :swatches="swatches"
                                    flat
                                    show-swatches
                                />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="pl-0" md="4">
                      <v-switch
                          v-model="code.status_id"
                          :false-value="14"
                          :true-value="1"
                          class="mx-4 mt-7"
                          dense
                          hide-details="auto"
                          label="Active"
                          required
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>


              <v-tooltip  bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      absolute
                      color="red"
                      dark
                      elevation="0"
                      fab
                      right
                      style="top:-5px;"
                      top
                      v-bind="attrs"
                      x-small
                      @click="deleteCode(cIndex)"
                      v-on="on"
                  >
                    <DeleteIcon/>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-col>
          </v-row>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue-color"
                    dark
                    fab
                    v-bind="attrs"
                    x-small
                    @click="addColorCode()"
                    v-on="on"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add new tag
            </v-tooltip>
          </div>
        </v-form>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <div class="text-base font-semibold black-text ml-1 mt-6">Booking Fields Configurations</div>
    <v-card class="mb-8 rounded-2 bordered shadow-0 mt-2">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <table class="table text-center table-bordered">
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
                <th class="text-center">Field Name</th>
                <th class="text-center">View</th>
                <th class="text-center">Mandatory</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(config, index) in field_configurations"
                  :key="index"
              >
                <td class="black-text font-bold text-sm  " style="font-weight: 500 !important;">{{ config.name }}</td>
                <td>
                  <v-btn
                      :style="
                          viewRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                      icon
                      @click="checkAsVisible(index)"
                  >
                    <TickIcon v-if="config.is_visible == 1 "/>
                    <CloseIcon v-else/>

                  </v-btn>
                </td>
                <td>
                  <v-btn
                      :style="
                          mandatoryRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                      icon
                      @click="checkAsMandatory(index)"
                  >
                    <TickIcon v-if="config.is_required == 1 "/>
                    <CloseIcon v-else/>

                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="d-flex justify-end">
      <v-btn
          class="white--text blue-color"
          @click="saveConfigurations($event)"
      >
        Update
      </v-btn>
    </div>
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
  </v-container>
</template>

<script>
import BackButton from "@/components/Common/BackButton.vue";
import DeleteIcon from "@/assets/images/retail/delete-bg-icon.svg";
import TickIcon from "@/assets/images/misc/config-table-tick-icon.svg";
import CloseIcon from "@/assets/images/misc/config-table-close-icon.svg";
import constants from "@/utils/constants";

export default {
  components: {CloseIcon, TickIcon, DeleteIcon, BackButton},
  computed: {},
  data() {
    return {
      deleted_categories: [],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      configuration:{
        enable_tag_restriction:false,
      },
      colorCodes: [
        // {
        //   name: null,
        //   status_id: 1,
        //   category_id: null,
        //   color_code: "#00AAAAFF",
        // },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      deleted_codes: [],
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      field_configurations: []
    }
  },
  mounted() {
    this.getConfiguration();
  },
  methods: {
    addColorCode() {
      this.colorCodes.push({
        name: null,
        status_id: 1,
        category_id: null,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    swatchStyle(cIndex) {
      const {color_code, menu} = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    getConfiguration() {
      this.$store.dispatch('loadEventFieldConfigurations').then( (response) => {
        if (response.status == 200 && response.data.status == true) {
          const data = response.data.data;
          if (data) {
            if (data.colorCodes.length) {
              this.colorCodes = data.colorCodes;
            }
            this.configuration = data.configuration;
            this.field_configurations = data.field_configurations;
          }
          this.deleted_codes = [];
          this.$forceUpdate();
          if (this.$refs.cform) {
            this.$refs.cform.resetValidation();
          }
        }
      })
      // this.$http
      //     .get(`venues/events/configuration`)
      //     .then((response) => {
      //       if (response.status == 200 && response.data.status == true) {
      //         const data = response.data.data;
      //         if (data) {
      //           if (data.colorCodes.length) {
      //             this.colorCodes = data.colorCodes;
      //           }
      //           // else {
      //           //   this.colorCodes = [
      //           //     {
      //           //       name: null,
      //           //       status_id: 1,
      //           //       category_id: null,
      //           //       color_code: "#00AAAAFF",
      //           //     },
      //           //   ];
      //           // }
      //           this.configuration = data.configuration;
      //           this.field_configurations = data.field_configurations;
      //         }
      //         this.deleted_codes = [];
      //         this.$forceUpdate();
      //         if (this.$refs.cform) {
      //           this.$refs.cform.resetValidation();
      //         }
      //       }
      //     })
      //     .catch((error) => {
      //       this.errorChecker(error);
      //     });
    },
    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this Tag?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "color_code",
        };
      }
    },
    saveConfigurations() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let data = {
        color_codes: this.colorCodes,
        field_configurations:this.field_configurations,
        configuration:this.configuration
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.showLoader();
      this.$http
          .post(`venues/events/configuration`, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Configuration saved");
              this.getConfiguration();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    checkAsMandatory(index) {
      let field = this.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.field_configurations[index].is_required =
            field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.field_configurations[index].is_visible =
            field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.field_configurations[index].is_required = 0;
        }
      }
    },
    confirmActions(data) {
      if (data.type === "color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      }
      this.confirmModel.id = null;
    },
    goToEvents() {
      this.$router.push({name: "Events"}, () => {
      });
    },
  },
}
</script>
<style>
.color-picker .v-input__append-inner {
  margin-top: 6px !important;
}
</style>